import { RouteNames } from "@/router/routes";
import SourceFiles from "@/views/CMS/SourceFiles/SourceFiles.vue";
import RouterView from "@/components/common/RouterView.vue";
import MainPagePromo from "@/views/CMS/MainPage/MainPagePromo.vue";
import MainPageWhyUndo from "@/views/CMS/MainPage/MainPageWhyUndo.vue";
import AboutUs from "@/views/CMS/AboutUs/AboutUs.vue";
import AboutUsOurStory from "@/views/CMS/AboutUs/AboutUsOurStory.vue";
import FaqQuestions from "@/views/CMS/Faq/FaqQuestions.vue";
import FaqCategories from "@/views/CMS/Faq/FaqCategories.vue";
import EditQuestion from "@/views/CMS/Faq/EditQuestion.vue";
import EUserEnum from "@/struct/user/EUserEnum";
var SystemRole = EUserEnum.SystemRole;
import SellInfoList from "@/views/CMS/SellInfo/SellInfoList.vue";
const cmsRoutes = [
    {
        path: "cms",
        name: RouteNames.CMS,
        component: RouterView,
        meta: {
            access: [SystemRole.SuperAdmin, SystemRole.Admin],
        },
        children: [
            {
                path: "source-files",
                name: RouteNames.CMS_SOURCE_FILES,
                component: SourceFiles,
                meta: {
                    access: [SystemRole.SuperAdmin, SystemRole.Admin],
                },
            },
            {
                path: "sell-info",
                name: RouteNames.CMS_SELL_INFO,
                component: SellInfoList,
                meta: {
                    access: [SystemRole.SuperAdmin, SystemRole.Admin],
                },
            },
            {
                path: "main-page",
                name: RouteNames.CMS_MAIN_PAGE,
                component: RouterView,
                redirect: { name: RouteNames.CMS_MAIN_PAGE_PROMO_LIST },
                meta: {
                    access: [SystemRole.SuperAdmin, SystemRole.Admin],
                },
                children: [
                    {
                        path: "promo",
                        name: RouteNames.CMS_MAIN_PAGE_PROMO_LIST,
                        component: MainPagePromo,
                        meta: {
                            access: [SystemRole.SuperAdmin, SystemRole.Admin],
                        }
                    },
                    {
                        path: "why-undo",
                        name: RouteNames.CMS_MAIN_PAGE_WHY_UNDO_LIST,
                        component: MainPageWhyUndo,
                        meta: {
                            access: [SystemRole.SuperAdmin, SystemRole.Admin],
                        }
                    }
                ]
            },
            {
                path: "about-us",
                name: RouteNames.CMS_ABOUT_US,
                component: RouterView,
                redirect: { name: RouteNames.CMS_ABOUT_US_LIST },
                meta: {
                    access: [SystemRole.SuperAdmin, SystemRole.Admin],
                },
                children: [
                    {
                        path: "",
                        name: RouteNames.CMS_ABOUT_US_LIST,
                        component: AboutUs,
                        meta: {
                            access: [SystemRole.SuperAdmin, SystemRole.Admin],
                        }
                    },
                    {
                        path: "our-story",
                        name: RouteNames.CMS_ABOUT_US_OUR_STORY_LIST,
                        component: AboutUsOurStory,
                        meta: {
                            access: [SystemRole.SuperAdmin, SystemRole.Admin],
                        }
                    }
                ]
            },
            {
                path: "faq",
                name: RouteNames.FAQ,
                component: RouterView,
                redirect: { name: RouteNames.FAQ_QUESTIONS },
                meta: {
                    access: [SystemRole.SuperAdmin, SystemRole.Admin],
                },
                children: [
                    {
                        path: "questions",
                        name: RouteNames.FAQ_QUESTIONS,
                        component: RouterView,
                        redirect: { name: RouteNames.FAQ_QUESTIONS_LIST },
                        meta: {
                            access: [SystemRole.SuperAdmin, SystemRole.Admin],
                        },
                        children: [
                            {
                                path: "",
                                name: RouteNames.FAQ_QUESTIONS_LIST,
                                component: FaqQuestions,
                                meta: {
                                    access: [SystemRole.SuperAdmin, SystemRole.Admin],
                                }
                            },
                            {
                                path: "create",
                                name: RouteNames.FAQ_QUESTIONS_CREATE,
                                component: EditQuestion,
                                meta: {
                                    access: [SystemRole.SuperAdmin, SystemRole.Admin],
                                }
                            },
                            {
                                path: "edit/:uuid",
                                name: RouteNames.FAQ_QUESTIONS_EDIT,
                                component: EditQuestion,
                                meta: {
                                    access: [SystemRole.SuperAdmin, SystemRole.Admin],
                                }
                            }
                        ]
                    },
                    {
                        path: "categories",
                        name: RouteNames.FAQ_QUESTIONS_CATEGORIES,
                        component: FaqCategories,
                        meta: {
                            access: [SystemRole.SuperAdmin, SystemRole.Admin],
                        }
                    }
                ]
            }
        ]
    }
];
export default cmsRoutes;
