import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
class DataOrder {
}
DataOrder.Breadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CRM } },
        text: i18n.t('breadcrumbs.crm'),
    },
    {
        text: i18n.t('breadcrumbs.order_management'),
        disabled: true
    }
];
DataOrder.BreadcrumbsView = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CRM } },
        text: i18n.t('breadcrumbs.crm'),
    },
    {
        text: i18n.t('breadcrumbs.order_management'),
        to: { name: RouteNames.ORDER_LIST }
    },
    {
        text: i18n.t('breadcrumbs.order_management_view'),
        disabled: true
    }
];
DataOrder.TableHeaders = [
    {
        text: i18n.t('table_headers.date'),
        value: "create_at",
        width: '15%'
    },
    {
        text: i18n.t('table_headers.order'),
        value: "id"
    },
    {
        text: i18n.t('table_headers.customer'),
        value: "first_name"
    },
    {
        text: i18n.t('table_headers.email'),
        value: "email"
    },
    {
        text: i18n.t('table_headers.total'),
        value: "amount"
    },
    {
        text: i18n.t('table_headers.order_status'),
        value: "status"
    },
    {
        text: i18n.t('table_headers.payment_status'),
        value: "payment_status"
    }
];
export default DataOrder;
