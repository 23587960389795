export var ECustomerEmissionType;
(function (ECustomerEmissionType) {
    ECustomerEmissionType[ECustomerEmissionType["BatteryCharge"] = 0] = "BatteryCharge";
    ECustomerEmissionType[ECustomerEmissionType["WebSiteVisit"] = 1] = "WebSiteVisit";
    ECustomerEmissionType[ECustomerEmissionType["OrderTracking"] = 2] = "OrderTracking";
    ECustomerEmissionType[ECustomerEmissionType["OrderShipping"] = 3] = "OrderShipping";
    ECustomerEmissionType[ECustomerEmissionType["PhysicalSimEmission"] = 4] = "PhysicalSimEmission";
    ECustomerEmissionType[ECustomerEmissionType["RegistrationAndActivation"] = 5] = "RegistrationAndActivation";
    ECustomerEmissionType[ECustomerEmissionType["VoiceUsage"] = 6] = "VoiceUsage";
    ECustomerEmissionType[ECustomerEmissionType["DataUsage"] = 7] = "DataUsage";
    ECustomerEmissionType[ECustomerEmissionType["SmsUsage"] = 8] = "SmsUsage";
    ECustomerEmissionType[ECustomerEmissionType["WearableEmission"] = 9] = "WearableEmission";
    ECustomerEmissionType[ECustomerEmissionType["DeviceEmission"] = 10] = "DeviceEmission";
    ECustomerEmissionType[ECustomerEmissionType["WebshopOrder"] = 11] = "WebshopOrder";
})(ECustomerEmissionType || (ECustomerEmissionType = {}));
