import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
class DataFaq {
}
DataFaq.FaqQuestionsBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CMS } },
        text: i18n.t('breadcrumbs.cms')
    },
    {
        to: { name: RouteNames.FAQ_QUESTIONS },
        text: i18n.t('breadcrumbs.support_faq')
    },
    {
        text: i18n.t('breadcrumbs.questions'),
        disabled: true
    },
];
DataFaq.FaqCategoriesBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CMS } },
        text: i18n.t('breadcrumbs.cms'),
    },
    {
        to: { name: RouteNames.FAQ_QUESTIONS_CATEGORIES },
        exact: true,
        text: i18n.t('breadcrumbs.support_faq')
    },
    {
        text: i18n.t('breadcrumbs.categories'),
        disabled: true
    },
];
DataFaq.QuestionsTableHeaders = [
    {
        text: i18n.t('table_headers.created_at'),
        value: "create_at"
    },
    {
        text: i18n.t('table_headers.question'),
        value: "question"
    },
    {
        text: i18n.t('table_headers.category'),
        value: "category"
    },
    {
        text: i18n.t('table_headers.action'),
        width: '15%',
        value: "action",
        sortable: false
    }
];
DataFaq.CategoriesTableHeaders = [
    {
        text: i18n.t('table_headers.created_at'),
        value: "create_at"
    },
    {
        text: i18n.t('table_headers.category_name'),
        value: "name"
    },
    {
        text: i18n.t('table_headers.action'),
        width: '15%',
        value: "action",
        sortable: false
    }
];
export default DataFaq;
