import axios from "axios";
export default class ApiDevice {
    static async GetDevices(session_uuid) {
        try {
            const result = await axios.get("/api/admin/device", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get devices");
        }
    }
    static async AddDevice(session_uuid, item) {
        try {
            const result = await axios.post("/api/admin/device", {
                model_list: item.model_list,
                model_name: item.model_name,
                capacity: item.capacity,
                brand: item.brand,
                production_emission: item.production_emission,
                logistics: item.logistics,
                use: item.use,
                eol: item.eol,
                total: item.total,
                total_use: item.total_use,
                default: item.default,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get devices");
        }
    }
    static async UpdateDevice(session_uuid, item) {
        try {
            const result = await axios.post("/api/admin/device/" + item.uuid, {
                model_list: item.model_list,
                model_name: item.model_name,
                capacity: item.capacity,
                brand: item.brand,
                production_emission: item.production_emission,
                logistics: item.logistics,
                use: item.use,
                eol: item.eol,
                total: item.total,
                total_use: item.total_use,
                default: item.default,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error update devices");
        }
    }
    static async DeleteDevice(session_uuid, uuid) {
        try {
            const result = await axios.delete("/api/admin/device/" + uuid, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get devices");
        }
    }
    static async GetWearable(session_uuid) {
        try {
            const result = await axios.get("/api/admin/device/wearable", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get wearable");
        }
    }
    static async AddWearable(session_uuid, item) {
        try {
            const result = await axios.post("/api/admin/device/wearable", {
                name: item.name,
                product_emission: item.product_emission,
                logistics: item.logistics,
                use: item.use,
                eol: item.eol,
                total: item.total,
                total_use: item.total_use,
                has_default: item.has_default != 0,
                brand: item.brand,
                type: item.type
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error add wearable");
        }
    }
    static async UpdateWearable(session_uuid, item) {
        try {
            const result = await axios.post("/api/admin/device/wearable/" + item.uuid, {
                name: item.name,
                product_emission: item.product_emission,
                logistics: item.logistics,
                use: item.use,
                eol: item.eol,
                total: item.total,
                total_use: item.total_use,
                has_default: item.has_default != 0,
                brand: item.brand,
                type: item.type
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error update wearable");
        }
    }
    static async DeleteWearable(session_uuid, uuid) {
        try {
            const result = await axios.delete("/api/admin/device/wearable/" + uuid, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error delete wearable");
        }
    }
    static async GetAvailableEsim(session_uuid) {
        try {
            const result = await axios.get("/api/admin/device/esim/available", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get esim");
        }
    }
    static async AddAvailableEsim(session_uuid, item) {
        try {
            const result = await axios.post("/api/admin/device/esim/available", {
                name: item.name,
                brand: item.brand,
                has_esim: item.has_esim != 0
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error add esim");
        }
    }
    static async UpdateAvailableEsim(session_uuid, item) {
        try {
            const result = await axios.post("/api/admin/device/esim/available/" + item.uuid, {
                name: item.name,
                brand: item.brand,
                has_esim: item.has_esim != 0
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error update esim");
        }
    }
    static async DeleteAvailableEsim(session_uuid, uuid) {
        try {
            const result = await axios.delete("/api/admin/device/esim/available/" + uuid, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error delete esim");
        }
    }
    static async UploadFileDevice(session_uuid, file) {
        try {
            const formData = new FormData();
            formData.append("devices", file);
            const result = await axios.post("/api/admin/device/sync/device", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async UploadFileWearable(session_uuid, file) {
        try {
            const formData = new FormData();
            formData.append("wearable", file);
            const result = await axios.post("/api/admin/device/sync/wearable", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async UploadFileEsim(session_uuid, file) {
        try {
            const formData = new FormData();
            formData.append("esim", file);
            const result = await axios.post("/api/admin/device/sync/esim", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
}
