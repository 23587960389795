var ECustomerEnum;
(function (ECustomerEnum) {
    let CustomerStatus;
    (function (CustomerStatus) {
        CustomerStatus[CustomerStatus["NoActive"] = 0] = "NoActive";
        CustomerStatus[CustomerStatus["Active"] = 1] = "Active";
        CustomerStatus[CustomerStatus["Created"] = 2] = "Created";
        CustomerStatus[CustomerStatus["Banned"] = 3] = "Banned";
        CustomerStatus[CustomerStatus["Deleted"] = 4] = "Deleted";
        CustomerStatus[CustomerStatus["__LENGHT"] = 5] = "__LENGHT";
    })(CustomerStatus = ECustomerEnum.CustomerStatus || (ECustomerEnum.CustomerStatus = {}));
    let CustomerType;
    (function (CustomerType) {
        CustomerType[CustomerType["NoCustomerType"] = 0] = "NoCustomerType";
        CustomerType[CustomerType["Persona"] = 1] = "Persona";
        CustomerType[CustomerType["Business"] = 2] = "Business";
    })(CustomerType = ECustomerEnum.CustomerType || (ECustomerEnum.CustomerType = {}));
    let CustomerPaymentStatus;
    (function (CustomerPaymentStatus) {
        CustomerPaymentStatus[CustomerPaymentStatus["NoInstall"] = 0] = "NoInstall";
        CustomerPaymentStatus[CustomerPaymentStatus["HasInstalled"] = 1] = "HasInstalled";
    })(CustomerPaymentStatus = ECustomerEnum.CustomerPaymentStatus || (ECustomerEnum.CustomerPaymentStatus = {}));
    let CustomerEmailStatus;
    (function (CustomerEmailStatus) {
        CustomerEmailStatus[CustomerEmailStatus["NoVerify"] = 0] = "NoVerify";
        CustomerEmailStatus[CustomerEmailStatus["HasVerify"] = 1] = "HasVerify";
        CustomerEmailStatus[CustomerEmailStatus["HasVerifyAndWaitVerifyForChange"] = 2] = "HasVerifyAndWaitVerifyForChange";
    })(CustomerEmailStatus = ECustomerEnum.CustomerEmailStatus || (ECustomerEnum.CustomerEmailStatus = {}));
    let PaymentMethod;
    (function (PaymentMethod) {
        PaymentMethod[PaymentMethod["NoSelected"] = -1] = "NoSelected";
        PaymentMethod[PaymentMethod["WTransfer"] = 0] = "WTransfer";
        PaymentMethod[PaymentMethod["DDebit"] = 1] = "DDebit";
    })(PaymentMethod = ECustomerEnum.PaymentMethod || (ECustomerEnum.PaymentMethod = {}));
})(ECustomerEnum || (ECustomerEnum = {}));
export default ECustomerEnum;
