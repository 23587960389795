export var ESettingGreenConfigEnum;
(function (ESettingGreenConfigEnum) {
    ESettingGreenConfigEnum[ESettingGreenConfigEnum["integer"] = 0] = "integer";
    ESettingGreenConfigEnum[ESettingGreenConfigEnum["string"] = 1] = "string";
    ESettingGreenConfigEnum[ESettingGreenConfigEnum["boolean"] = 2] = "boolean";
    ESettingGreenConfigEnum[ESettingGreenConfigEnum["integer_single_list"] = 3] = "integer_single_list";
    ESettingGreenConfigEnum[ESettingGreenConfigEnum["string_single_list"] = 4] = "string_single_list";
    ESettingGreenConfigEnum[ESettingGreenConfigEnum["integer_list"] = 5] = "integer_list";
    ESettingGreenConfigEnum[ESettingGreenConfigEnum["string_list"] = 6] = "string_list";
})(ESettingGreenConfigEnum || (ESettingGreenConfigEnum = {}));
