import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
class DataSourceFiles {
}
DataSourceFiles.Breadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CMS } },
        text: i18n.t('breadcrumbs.cms'),
    },
    {
        text: i18n.t('breadcrumbs.source_files_for_content'),
        disabled: true
    },
];
DataSourceFiles.TableHeaders = [
    {
        text: i18n.t('table_headers.created_at'),
        value: "create_at",
        width: "15%"
    },
    {
        text: i18n.t('table_headers.file_name'),
        value: "file_name"
    },
    {
        text: i18n.t('table_headers.anchor'),
        value: "anchor"
    },
    {
        text: i18n.t('table_headers.description'),
        value: "description"
    },
    {
        text: i18n.t('table_headers.action'),
        value: "action",
        sortable: false,
        width: "15%"
    }
];
export default DataSourceFiles;
