export var EDeviceBrand;
(function (EDeviceBrand) {
    EDeviceBrand["Apple"] = "Apple";
    EDeviceBrand["Google"] = "Google";
    EDeviceBrand["Lg"] = "Lg";
    EDeviceBrand["Motorola"] = "Motorola";
    EDeviceBrand["Samsung"] = "Samsung";
    EDeviceBrand["Huawei"] = "Huawei";
    EDeviceBrand["Oppo"] = "Oppo";
    EDeviceBrand["Microsoft"] = "Microsoft";
    EDeviceBrand["myPhone"] = "myPhone";
    EDeviceBrand["Sony"] = "Sony";
    EDeviceBrand["Xiaomi"] = "Xiaomi";
    EDeviceBrand["Nokia"] = "Nokia";
    EDeviceBrand["Sharp"] = "Sharp";
    EDeviceBrand["Fairphone"] = "Fairphone";
    EDeviceBrand["Other"] = "Other";
})(EDeviceBrand || (EDeviceBrand = {}));
export var EDeviceModel;
(function (EDeviceModel) {
    let Apple;
    (function (Apple) {
        Apple["iPhone11"] = "iPhone 11";
        Apple["iPhone11Pro"] = "iPhone 11 Pro";
        Apple["iPhone11ProMax"] = "iPhone 11 Pro Max";
        Apple["iPhoneXS"] = "iPhone XS";
        Apple["iPhoneXSMax"] = "iPhone XS Max";
        Apple["iPhoneXR"] = "iPhone XR";
        Apple["iPhoneSE2020"] = "iPhone SE 2020";
        Apple["iPhone12"] = "iPhone 12";
        Apple["iPhone12Mini"] = "iPhone 12 Mini";
        Apple["iPhone12Pro"] = "iPhone 12 Pro";
        Apple["iPhone12ProMax"] = "iPhone 12 Pro Max";
        Apple["iPhone13"] = "iPhone 13";
        Apple["iPhone13Mini"] = "iPhone 13 Mini";
        Apple["iPhone13Pro"] = "iPhone 13 Pro";
        Apple["iPhone13ProMax"] = "iPhone 13 Pro Max";
        Apple["iPhoneSE2022"] = "iPhone SE 2022";
        Apple["iPhone14"] = "iPhone 14";
        Apple["iPhone14Pro"] = "iPhone 14 Pro";
        Apple["iPhone14ProMax"] = "iPhone 14 Pro Max";
        Apple["iPhone14Plus"] = "iPhone 14 Plus";
        Apple["iPad_7"] = "iPad (7)";
        Apple["iPad_8"] = "iPad (8)";
        Apple["iPadAir_3"] = "iPad Air (3)";
        Apple["iPadAir_4"] = "iPad Air (4)";
        Apple["iPadmini_5"] = "iPad mini (5)";
        Apple["iPadPro11_1"] = "iPad Pro 11 (1)";
        Apple["iPadPro11_2"] = "iPad Pro 11 (2)";
        Apple["iPadPro12_3"] = "iPad Pro 12 (3)";
        Apple["iPadPro12_4"] = "iPad Pro 12 (4)";
        Apple["iPadPro2020"] = "iPad Pro 2020";
        Apple["iPadPro2021"] = "iPad Pro 2021";
        Apple["iPadAir2022"] = "iPad Air 2022";
    })(Apple = EDeviceModel.Apple || (EDeviceModel.Apple = {}));
    let Google;
    (function (Google) {
        Google["GooglePixel4XL"] = "Google Pixel 4 XL";
        Google["GooglePixel4"] = "Google Pixel 4";
        Google["GooglePixel4a"] = "Google Pixel 4a";
        Google["GooglePixel4aXL"] = "Google Pixel 4a XL";
        Google["GooglePixel"] = "Google Pixel";
        Google["GooglePixel2"] = "Google Pixel 2";
        Google["GooglePixel2X"] = "Google Pixel 2X";
        Google["GooglePixel3"] = "Google Pixel 3";
        Google["GooglePixel3XL"] = "Google Pixel 3XL";
        Google["GooglePixel3A"] = "Google Pixel 3A";
        Google["GooglePixel3AXL"] = "Google Pixel 3A XL";
        Google["GooglePixel4Ac5D"] = "Google Pixel 4A c 5D";
        Google["GooglePixel5"] = "Google Pixel 5";
        Google["GooglePixel5Pro"] = "Google Pixel 5Pro";
        Google["GooglePixel6"] = "Google Pixel 6";
        Google["GooglePixel6Pro"] = "Google Pixel 6Pro";
        Google["GooglePixel7"] = "Google Pixel 7";
        Google["GooglePixel7Pro"] = "Google Pixel 7Pro";
        Google["GooglePixel6a"] = "Google Pixel 6a";
    })(Google = EDeviceModel.Google || (EDeviceModel.Google = {}));
    let Lg;
    (function (Lg) {
        Lg["Nexus6"] = "Nexus 6";
        Lg["Nexus6P"] = "Nexus 6P";
        Lg["LGG7ThinQ"] = "LG G7 ThinQ (Dual SIM)";
        Lg["LGV35ThinQ"] = "LG V35 ThinQ (Dual SIM)";
    })(Lg = EDeviceModel.Lg || (EDeviceModel.Lg = {}));
    let Motorola;
    (function (Motorola) {
        Motorola["MotorolaRAZR"] = "Motorola RAZR";
        Motorola["MotoG6"] = "Moto G6 (Dual SIM)";
        Motorola["MotoX4"] = "Moto X4 (Dual SIM)";
        Motorola["MotorolaEdge"] = "Motorola Edge (2022)";
        Motorola["MotorolaRazr"] = "Motorola Razr (2022)";
    })(Motorola = EDeviceModel.Motorola || (EDeviceModel.Motorola = {}));
    let Samsung;
    (function (Samsung) {
        Samsung["SamsungFold"] = "Samsung Fold";
        Samsung["SamsungGearS3ClassicLTE"] = "Samsung Gear S3 Classic LTE";
        Samsung["SamsungGearS3frontier3GLTE"] = "Samsung Gear S3 frontier 3G LTE";
        Samsung["SamsungS20"] = "Samsung S20";
        Samsung["SamsungS20Ultra"] = "Samsung S20 Ultra";
        Samsung["SamsungS20Plus"] = "Samsung S20+";
        Samsung["SamsungNote20"] = "Samsung Note 20";
        Samsung["SamsungNote20Ultra"] = "Samsung Note 20Ultra";
        Samsung["SamsungS215G"] = "Samsung S 215 G";
        Samsung["SamsungS21Ultra5G"] = "Samsung S21 Ultra 5G";
        Samsung["SamsungZFlip"] = "Samsung Z Flip";
        Samsung["SamsungZFold2"] = "Samsung Z Fold 2";
        Samsung["GalaxyZFold3"] = "Galaxy Z Fold 3";
        Samsung["SamsungGalaxyZFlip2"] = "Samsung Galaxy Z Flip 2";
        Samsung["SamsungGalaxyZFlip3"] = "Samsung Galaxy Z Flip 3";
        Samsung["SamsungGalaxyS22"] = "Samsung Galaxy S22";
        Samsung["SamsungGalaxyS22Ultra"] = "Samsung Galaxy S22 Ultra";
        Samsung["SamsungGalaxyA54"] = "Samsung Galaxy A54";
        Samsung["SamsungGalaxyS23"] = "Samsung Galaxy S23";
        Samsung["SamsungGalaxyS23Ultra"] = "Samsung Galaxy S23 Ultra";
        Samsung["SamsungGalaxyZFold4"] = "Samsung Galaxy Z Fold 4";
        Samsung["SamsungGalaxyZFlip4"] = "Samsung Galaxy Z Flip 4";
    })(Samsung = EDeviceModel.Samsung || (EDeviceModel.Samsung = {}));
    let Huawei;
    (function (Huawei) {
        Huawei["HuaweiP40Pro"] = "Huawei P40 Pro";
        Huawei["HuaweiP40"] = "Huawei P40";
        Huawei["HuaweiP40ProPlus"] = "Huawei P40 Pro+";
        Huawei["HonorMagic5Pro"] = "Honor Magic 5 Pro";
    })(Huawei = EDeviceModel.Huawei || (EDeviceModel.Huawei = {}));
    let Oppo;
    (function (Oppo) {
        Oppo["OppoFindX3"] = "Oppo Find X3";
        Oppo["OppoFindX3Pro"] = "Oppo Find X3 Pro";
        Oppo["OppoFindX5"] = "Oppo Find X5";
        Oppo["OppoFindX5Pro"] = "Oppo Find X5 Pro";
    })(Oppo = EDeviceModel.Oppo || (EDeviceModel.Oppo = {}));
    let Microsoft;
    (function (Microsoft) {
        Microsoft["MicrosoftSurfaceDuo"] = "Microsoft Surface Duo";
        Microsoft["MicrosoftSurfaceTabletPro"] = "Microsoft Surface Tablet Pro";
        Microsoft["MicrosoftSurfaceGo2"] = "Microsoft Surface Go2";
        Microsoft["MicrosoftSurfacePro5LTE"] = "Microsoft Surface Pro 5 LTE";
    })(Microsoft = EDeviceModel.Microsoft || (EDeviceModel.Microsoft = {}));
    let myPhone;
    (function (myPhone) {
        myPhone["HammerBlade3"] = "Hammer Blade 3";
        myPhone["HammerExplorerPro"] = "Hammer Explorer Pro";
    })(myPhone = EDeviceModel.myPhone || (EDeviceModel.myPhone = {}));
    let Sony;
    (function (Sony) {
        Sony["SonyXperia10IIILite"] = "Sony Xperia 10 III Lite";
        Sony["SonyXperia5IV"] = "Sony Xperia 5 IV";
        Sony["SonyXperia10IV"] = "Sony Xperia 10 IV";
        Sony["SonyXperia1IV"] = "Sony Xperia 1 IV";
    })(Sony = EDeviceModel.Sony || (EDeviceModel.Sony = {}));
    let Xiaomi;
    (function (Xiaomi) {
        Xiaomi["Xiaomi12TPro"] = "Xiaomi 12T Pro";
    })(Xiaomi = EDeviceModel.Xiaomi || (EDeviceModel.Xiaomi = {}));
    let Nokia;
    (function (Nokia) {
        Nokia["NokiaX30"] = "Nokia X30";
        Nokia["NokiaG60"] = "Nokia G60";
    })(Nokia = EDeviceModel.Nokia || (EDeviceModel.Nokia = {}));
    let Sharp;
    (function (Sharp) {
        Sharp["SharpAquoswish"] = "Sharp Aquos wish";
        Sharp["SharpAquoszero6"] = "Sharp Aquos zero 6";
    })(Sharp = EDeviceModel.Sharp || (EDeviceModel.Sharp = {}));
    let Fairphone;
    (function (Fairphone) {
        Fairphone["Fairphone4"] = "Fairphone 4";
    })(Fairphone = EDeviceModel.Fairphone || (EDeviceModel.Fairphone = {}));
})(EDeviceModel || (EDeviceModel = {}));
