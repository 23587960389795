import axios from "axios";
const state = {
    customerDetail: null,
    emissionHistoryUUID: null,
    compensationHistoryUUID: null,
    villageHistoryUUID: null,
    customerMailUuid: null,
    customerHttpHistoryUuid: null,
    customerPushHistoryUuid: null,
    customerInvoiceListUuid: null,
    customerSessionListUuid: null,
};
const getters = {
    getOpenCustomerDetail(state) {
        return state.customerDetail;
    },
    getEmissionHistoryUUID(state) {
        return state.emissionHistoryUUID;
    },
    getCompensationHistoryUUID(state) {
        return state.compensationHistoryUUID;
    },
    getVillageHistoryUUID(state) {
        return state.villageHistoryUUID;
    },
    getCustomerMailUuid(state) {
        return state.customerMailUuid;
    },
    getCustomerHttpHistoryUuid(state) {
        return state.customerHttpHistoryUuid;
    },
    getCustomerPushHistoryUuid(state) {
        return state.customerPushHistoryUuid;
    },
    getCustomerInvoiceListUuid(state) {
        return state.customerInvoiceListUuid;
    },
    getCustomerSessionListUuid(state) {
        return state.customerSessionListUuid;
    },
};
const mutations = {
    setOpenCustomerDetail(state, payload) {
        state.customerDetail = payload;
    },
    setEmissionHistoryUUID(state, payload) {
        state.emissionHistoryUUID = payload;
    },
    setCompensationHistoryUUID(state, payload) {
        state.compensationHistoryUUID = payload;
    },
    setVillageHistoryUUID(state, payload) {
        state.villageHistoryUUID = payload;
    },
    setCustomerMailUuid(state, payload) {
        state.customerMailUuid = payload;
    },
    setCustomerHttpHistoryUuid(state, payload) {
        state.customerHttpHistoryUuid = payload;
    },
    setCustomerPushHistoryUuid(state, payload) {
        state.customerPushHistoryUuid = payload;
    },
    setCustomerInvoiceListUuid(state, payload) {
        state.customerInvoiceListUuid = payload;
    },
    setCustomerSessionListUuid(state, payload) {
        state.customerSessionListUuid = payload;
    },
};
const actions = {
    async doOpenCustomerDetail(ctx, customer_uuid) {
        if (customer_uuid == null) {
            ctx.commit("setOpenCustomerDetail", null);
            return;
        }
        const sessionUUID = localStorage.getItem("XSessionUUID");
        const response = await axios.get("/api/admin/v2/customer/" + customer_uuid, {
            headers: {
                "X-Session-Token": sessionUUID,
            },
        });
        if (typeof response != "boolean") {
            ctx.commit("setOpenCustomerDetail", response.data.response);
        }
        else {
            ctx.commit("setOpenCustomerDetail", null);
        }
        return;
    },
    async doSetEmissionHistoryUUID(ctx, customer_uuid) {
        ctx.commit("setEmissionHistoryUUID", customer_uuid);
    },
    async doSetCompensationHistoryUUID(ctx, customer) {
        ctx.commit("setCompensationHistoryUUID", customer);
    },
    async doSetVillageHistoryUUID(ctx, customer) {
        ctx.commit("setVillageHistoryUUID", customer);
    },
    async doSetCustomerMailUuid(ctx, uuid) {
        console.log("uuid", uuid);
        ctx.commit("setCustomerMailUuid", uuid);
    },
    async doSetCustomerHttpHistoryUuid(ctx, uuid) {
        ctx.commit("setCustomerHttpHistoryUuid", uuid);
    },
    async doSetCustomerPushHistoryUuid(ctx, uuid) {
        ctx.commit("setCustomerPushHistoryUuid", uuid);
    },
    async doSetCustomerInvoiceListUuid(ctx, uuid) {
        ctx.commit("setCustomerInvoiceListUuid", uuid);
    },
    async doSetCustomerSessionListUuid(ctx, uuid) {
        ctx.commit("setCustomerSessionListUuid", uuid);
    },
};
export default {
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};
