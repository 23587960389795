import { RouteNames } from "@/router/routes";
import EmailNotifications from "@/views/Settings/EmailNotification/EmailNotifications.vue";
import EmailNotificationsEdit from "@/views/Settings/EmailNotification/EmailNotificationsEdit.vue";
import RouterView from "@/components/common/RouterView.vue";
import ApplicationSetting from "@/views/Settings/ApplicationSetting.vue";
import Users from "@/views/Settings/UsersManagement/Users.vue";
import EditUser from "@/views/Settings/UsersManagement/EditUser.vue";
import ApplicationTools from "@/views/Settings/ApplicationTools.vue";
import Uat from "@/views/Uat.vue";
const settingsRoutes = [
    {
        path: "settings",
        name: RouteNames.SETTINGS,
        component: RouterView,
        redirect: { name: RouteNames.SETTINGS_EMAIL_NOTIFICATIONS },
        children: [
            {
                path: "email-notifications",
                name: RouteNames.SETTINGS_EMAIL_NOTIFICATIONS,
                component: RouterView,
                redirect: { name: RouteNames.SETTINGS_EMAIL_NOTIFICATIONS_LIST },
                children: [
                    {
                        path: "",
                        name: RouteNames.SETTINGS_EMAIL_NOTIFICATIONS_LIST,
                        component: EmailNotifications,
                    },
                    {
                        path: "create",
                        name: RouteNames.SETTINGS_EMAIL_NOTIFICATIONS_CREATE,
                        component: EmailNotificationsEdit,
                    },
                    {
                        path: "edit/:uuid",
                        name: RouteNames.SETTINGS_EMAIL_NOTIFICATIONS_EDIT,
                        component: EmailNotificationsEdit,
                    },
                ],
            },
            {
                path: "application-setting",
                name: RouteNames.SETTINGS_APPLICATION,
                component: ApplicationSetting,
            },
            {
                path: "uat-test",
                name: RouteNames.UAT,
                component: Uat,
            },
            {
                path: "users",
                name: RouteNames.ADMIN_USERS,
                component: RouterView,
                redirect: { name: RouteNames.ADMIN_USERS_LIST },
                children: [
                    {
                        path: "",
                        name: RouteNames.ADMIN_USERS_LIST,
                        component: Users,
                    },
                    {
                        path: "edit-user/:user_uuid",
                        name: RouteNames.ADMIN_USERS_EDIT,
                        component: EditUser,
                    },
                ],
            },
            {
                path: "tools",
                name: RouteNames.TOOLS_APPLICATION,
                component: ApplicationTools,
            },
        ],
    },
];
export default settingsRoutes;
