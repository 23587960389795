import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
class DataFeedback {
}
DataFeedback.DataFeedbackContactsBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CRM } },
        text: i18n.t('breadcrumbs.crm'),
    },
    {
        to: { name: RouteNames.CMS_FEEDBACK_CONTRACTS_LIST },
        text: i18n.t('breadcrumbs.feedback_messages'),
    },
    {
        disabled: true,
        text: i18n.t('breadcrumbs.contact_us'),
    }
];
DataFeedback.DataFeedbackContactsViewBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CMS } },
        text: i18n.t('breadcrumbs.cms'),
    },
    {
        to: { name: RouteNames.CMS_FEEDBACK_CONTRACTS_LIST },
        text: i18n.t('breadcrumbs.feedback_messages'),
    },
    {
        to: { name: RouteNames.CMS_FEEDBACK_CONTRACTS_LIST },
        text: i18n.t('breadcrumbs.contact_us'),
    },
    {
        text: i18n.t('feedback.view_message'),
        disabled: true
    }
];
DataFeedback.DataFeedbackPartnersBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CRM } },
        text: i18n.t('breadcrumbs.crm'),
    },
    {
        to: { name: RouteNames.CMS_FEEDBACK_PARTNERS_LIST },
        text: i18n.t('breadcrumbs.feedback_messages'),
    },
    {
        disabled: true,
        text: i18n.t('breadcrumbs.become_a_partner'),
    }
];
DataFeedback.DataFeedbackPartnersViewBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CMS } },
        text: i18n.t('breadcrumbs.cms'),
    },
    {
        to: { name: RouteNames.CMS_FEEDBACK_PARTNERS_LIST },
        text: i18n.t('breadcrumbs.feedback_messages'),
    },
    {
        to: { name: RouteNames.CMS_FEEDBACK_PARTNERS_LIST },
        text: i18n.t('breadcrumbs.become_a_partner'),
    },
    {
        text: i18n.t('feedback.view_message'),
        disabled: true
    }
];
DataFeedback.ContactsTableHeaders = [
    {
        text: i18n.t("table_headers.created_at"),
        value: "create_at",
        width: '15%'
    },
    {
        text: i18n.t("table_headers.topic"),
        value: "topic",
    },
    {
        text: i18n.t("table_headers.name"),
        value: "name",
    },
    {
        text: i18n.t("table_headers.email"),
        value: "mail",
    },
    {
        text: i18n.t("table_headers.phone_number"),
        value: "phone",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "action",
        sortable: false
    }
];
DataFeedback.PartnersTableHeaders = [
    {
        text: i18n.t("table_headers.created_at"),
        value: "create_at",
        width: '15%'
    },
    {
        text: i18n.t("table_headers.contact_name"),
        value: "contact_name",
    },
    {
        text: i18n.t("table_headers.email"),
        value: "email",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "action",
        sortable: false
    }
];
export default DataFeedback;
