import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
class DataDevice {
}
DataDevice.Breadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CRM } },
        text: i18n.t('breadcrumbs.crm'),
    },
    {
        disabled: true,
        text: i18n.t('breadcrumbs.devices'),
    }
];
DataDevice.BreadcrumbsWearable = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CRM } },
        text: i18n.t('breadcrumbs.crm'),
    },
    {
        disabled: true,
        text: i18n.t('breadcrumbs.wearables'),
    }
];
DataDevice.BreadcrumbsESim = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CRM } },
        text: i18n.t('breadcrumbs.crm'),
    },
    {
        disabled: true,
        text: i18n.t('breadcrumbs.e_sim'),
    }
];
DataDevice.TableHeadersDevice = [
    {
        text: "Brand",
        value: "brand",
        width: '15%'
    },
    {
        text: "Model name",
        value: "model_name",
        width: '25%'
    },
    {
        text: "Production emission (KG)",
        value: "production_emission"
    },
    {
        text: "Total use (KG)",
        value: "total_use"
    },
    {
        text: 'Actions',
        value: 'actions',
        width: '15%',
        sortable: false
    }
];
DataDevice.TableHeadersWearable = [
    {
        text: "Create at",
        value: "create_at"
    },
    {
        text: "Brand",
        value: "brand",
        width: '15%'
    },
    {
        text: "Name",
        value: "name",
        width: '25%'
    },
    {
        text: "Production emission (KG)",
        value: "product_emission"
    },
    {
        text: "Total use (KG)",
        value: "total_use"
    },
    {
        text: 'Actions',
        value: 'actions',
        width: '15%',
        sortable: false
    }
];
DataDevice.TableHeadersEsim = [
    {
        text: "Brand",
        value: "brand",
        width: '15%'
    },
    {
        text: "Name",
        value: "name"
    },
    {
        text: 'Actions',
        value: 'actions',
        width: '15%',
        sortable: false
    }
];
export default DataDevice;
