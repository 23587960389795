import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
class DataPromoHistory {
}
DataPromoHistory.Breadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CRM } },
        text: i18n.t("breadcrumbs.crm"),
    },
    {
        disabled: true,
        text: i18n.t("breadcrumbs.promo_usage"),
    },
];
DataPromoHistory.TableHeaders = [
    {
        text: i18n.t("table_headers.created_at"),
        value: "created_at",
        width: "15%",
    },
    {
        text: i18n.t("promo_usage.customer_name"),
        value: "customer.display_name",
    },
    {
        text: i18n.t("promo_usage.email"),
        value: "customer.email",
    },
    {
        text: i18n.t("promo_usage.order_product_tariff"),
        value: "order_product.offer",
    },
    {
        text: i18n.t("promo_usage.order_product_status"),
        value: "order_product.status",
    },
    {
        text: i18n.t("promo_usage.order_product_msisdn"),
        value: "order_product.msisdn",
    },
    {
        text: i18n.t("promo_usage.promo"),
        value: "promo.code",
    },
    {
        text: i18n.t("table_headers.action"),
        width: "10%",
        value: "actions",
        sortable: false,
    },
];
export default DataPromoHistory;
