import axios from "axios";
class ApiAdminPanel {
    static async ChangeVillageValues(session_uuid, village_uuid, value_month, value_total) {
        try {
            const result = await axios.post("/api/admin/village-projects/" + village_uuid + "/green", {
                value_month: value_month,
                value_total: value_total,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get files");
        }
    }
    static async GetUsers(session_uuid) {
        try {
            const result = await axios.get("/api/admin/get-users", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get users");
        }
    }
    static async GetUser(session_uuid, user_uuid) {
        try {
            const result = await axios.post("/api/admin/get-user", {
                user_uuid: user_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            console.error(e);
            return undefined;
        }
    }
    static async UpdateUserData(first_name, last_name, phone, session_uuid, user_uuid) {
        try {
            const result = await axios.post("/api/admin/change-user-info", {
                first_name: first_name,
                last_name: last_name,
                phone: phone,
                user_uuid: user_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get users");
        }
    }
    static async UpdateUserStatus(user_uuid, session_uuid, status) {
        try {
            const result = await axios.post("/api/admin/change-status", {
                status: status,
                user_uuid: user_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error update status");
        }
    }
    static async UpdateUserRole(user_uuid, session_uuid, role) {
        try {
            const result = await axios.post("/api/admin/change-role", {
                system_role: role,
                user_uuid: user_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error update role");
        }
    }
    static async DeleteUser(session_uuid, user_uuid) {
        try {
            const result = await axios.post("/api/admin/delete-user", {
                user_uuid: user_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get users");
        }
    }
    static async UpdateEmail(email, user_uuid, session_uuid) {
        try {
            const result = await axios.post("/api/admin/update-email", {
                email: email,
                user_uuid: user_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get users");
        }
    }
    static async UpdatePassword(password, user_uuid, session_uuid) {
        try {
            const result = await axios.post("/api/admin/update-password", {
                password: password,
                user_uuid: user_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get users");
        }
    }
    static async LoginOnUser(session_uuid, email) {
        try {
            const result = await axios.post("/api/admin/login-on-user-session", {
                email: email,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.session_uuid;
        }
        catch (e) {
            return undefined;
        }
    }
    static async UploadFile(session_uuid, file, currentDir) {
        const formData = new FormData();
        formData.append("file", file);
        try {
            const result = await axios.post("/api/admin/files/upload", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "x-session-token": session_uuid,
                },
                params: {
                    currentDir: currentDir,
                },
            });
            return result.data.response[0];
        }
        catch (e) {
            return undefined;
        }
    }
    static async GetFiles(session_uuid, file_name) {
        try {
            const result = await axios.post("/api/file/get-files", {
                file_name: file_name,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
                responseType: "blob",
            });
            return result.data;
        }
        catch (e) {
            throw new Error("Error get file");
        }
    }
    static async GetAddons(session_uuid) {
        try {
            const result = await axios.get("/api/admin/offer/list", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get addons");
        }
    }
    static async UpdateAddon(session_uuid, uuid, name, item) {
        try {
            const result = await axios.post("/api/admin/offer/update", {
                uuid,
                name,
                internal_details: JSON.stringify(item),
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error update addon");
        }
    }
    static async GetProjects(session_uuid) {
        try {
            const result = await axios.get("/api/admin/portfolio-projects", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            return undefined;
        }
    }
    static async AddProject(session_uuid, item) {
        try {
            const result = await axios.post("/api/admin/portfolio-projects", {
                projectType: item.projectType,
                project: item.project,
                certificationLevel: item.certificationLevel,
                vintage: item.vintage,
                cost: item.cost,
                creditType: item.creditType,
                location: item.location,
                permanenceInYears: item.permanenceInYears,
                oxfordCategory: item.oxfordCategory,
                description: item.description,
                link: item.link,
                img: item.img,
                img_mobile: item.img_mobile,
                short_description: item.short_description,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error add project");
            return undefined;
        }
    }
    static async UpdateProject(session_uuid, item) {
        try {
            const result = await axios.post(`/api/admin/portfolio-projects/${item.uuid}`, {
                projectType: item.projectType,
                project: item.project,
                certificationLevel: item.certificationLevel,
                vintage: item.vintage,
                cost: item.cost,
                creditType: item.creditType,
                location: item.location,
                permanenceInYears: item.permanenceInYears,
                oxfordCategory: item.oxfordCategory,
                description: item.description,
                link: item.link,
                img: item.img,
                img_mobile: item.img_mobile,
                short_description: item.short_description,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error update project");
        }
    }
    static async DeleteProject(session_uuid, uuid) {
        try {
            const result = await axios.delete(`/api/admin/portfolio-projects/${uuid}`, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error delete project");
        }
    }
    static async GetVillages(session_uuid) {
        try {
            const result = await axios.get("/api/admin/village-projects", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            return undefined;
        }
    }
    static async UpdateVillage(session_uuid, item) {
        try {
            const result = await axios.post(`/api/admin/village-projects/${item.uuid}`, {
                title: item.title,
                desc: item.desc,
                use_tree: item.use_tree,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error update project");
        }
    }
    static async DeleteVillage(session_uuid, uuid) {
        try {
            const result = await axios.delete(`/api/admin/village-projects/${uuid}`, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error delete project");
        }
    }
    static async GetPromos(session_uuid) {
        try {
            const result = await axios.get("/api/admin/promo/list", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            return undefined;
        }
    }
    static async AddPromo(session_uuid, item) {
        try {
            const result = await axios.post("/api/admin/promo", {
                code: item.code,
                discount: item.discount,
                duration: item.duration,
                is_enabled: item.is_enabled,
                valid_from: item.valid_from,
                valid_to: item.valid_to,
                description: item.description,
                description_fr: item.description_fr,
                description_du: item.description_du,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error add promo");
            return undefined;
        }
    }
    static async UpdatePromo(session_uuid, item) {
        try {
            const result = await axios.post(`/api/admin/promo/${item.uuid}`, {
                code: item.code,
                discount: item.discount,
                duration: item.duration,
                is_enabled: item.is_enabled,
                valid_from: item.valid_from,
                valid_to: item.valid_to,
                description: item.description,
                description_fr: item.description_fr,
                description_du: item.description_du,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error update promo");
        }
    }
    static async GetPromoHistory(session_uuid) {
        try {
            const result = await axios.get("/api/admin/promo-history", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            return undefined;
        }
    }
    static async DownloadPromoHistoryFile(session_uuid) {
        try {
            const result = await axios.get("/api/admin/promo-history/download", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
                responseType: "blob",
            });
            return result;
        }
        catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
export default ApiAdminPanel;
