import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
import { ESettingLanguage } from "@/struct/settings/ESettingLanguage";
class DataSettings {
}
DataSettings.SettingsLanguageBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.SETTINGS } },
        text: i18n.t('breadcrumbs.settings'),
    },
    {
        text: i18n.t('breadcrumbs.language'),
        disabled: true
    }
];
DataSettings.SettingsGreenConfigBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CRM } },
        text: i18n.t('breadcrumbs.crm'),
    },
    {
        text: i18n.t('breadcrumbs.green-config'),
        disabled: true
    }
];
DataSettings.SettingsApplicationBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.SETTINGS } },
        text: i18n.t('breadcrumbs.settings'),
    },
    {
        text: i18n.t('breadcrumbs.application'),
        disabled: true
    }
];
DataSettings.TableHeadersGreenConfig = [
    {
        text: i18n.t('table_headers.description'),
        value: "description"
    },
    {
        text: i18n.t('table_headers.value'),
        value: "value"
    },
    {
        text: 'Actions',
        value: 'actions',
        width: '10%',
        sortable: false
    }
];
DataSettings.LanguageList = [
    {
        title: 'English',
        lang: 'eng',
        disabled: false
    },
    {
        title: 'Dutch',
        lang: 'du',
        disabled: true
    },
    {
        title: 'French',
        lang: 'fr',
        disabled: true
    }
];
DataSettings.RadioLanguages = [
    {
        label: i18n.t('general.english'),
        value: ESettingLanguage.eng
    },
    {
        label: i18n.t('general.dutch'),
        value: ESettingLanguage.du
    },
    {
        label: i18n.t('general.french'),
        value: ESettingLanguage.fr
    },
];
export default DataSettings;
