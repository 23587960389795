import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
class DataAddonCategories {
}
DataAddonCategories.Breadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CRM } },
        text: i18n.t("breadcrumbs.crm"),
    },
    {
        text: i18n.t("breadcrumbs.addon_categories"),
        disabled: true
    }
];
DataAddonCategories.TableHeaders = [
    {
        text: i18n.t("table_headers.created_at"),
        value: "created_at"
    },
    {
        text: i18n.t("table_headers.name"),
        value: "name",
    },
    {
        text: i18n.t("table_headers.internal_type"),
        value: "internal_details.type",
    },
    {
        text: i18n.t("table_headers.price"),
        value: "internal_details.price",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "action",
        sortable: false,
        width: "10%"
    }
];
export default DataAddonCategories;
