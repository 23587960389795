import RouterView from "@/components/common/RouterView.vue";
import { RouteNames } from "@/router/routes";
import Orders from "@/views/CRM/Order/Orders.vue";
import ViewOrder from "@/views/CRM/Order/ViewOrder.vue";
const orderRoutes = [
    {
        path: "order",
        name: RouteNames.ORDER,
        component: RouterView,
        redirect: { name: RouteNames.ORDER_LIST },
        children: [
            {
                path: "list",
                name: RouteNames.ORDER_LIST,
                component: Orders
            },
            {
                path: "view/:uuid",
                name: RouteNames.ORDER_VIEW,
                component: ViewOrder
            }
        ]
    }
];
export default orderRoutes;
