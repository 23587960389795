import Vue from "vue";
import Vuex from "vuex";
import TenantStore from "@/store/modules/common/tenant/TenantStore";
import CurrentUserSessionStore from "@/store/modules/common/current-user-session/CurrentUserSessionStore";
import NewOrdersStore from "@/store/modules/common/preview/NewOrdersStore";
import CommonStore from "@/store/modules/common/common/CommonStore";
import ModalStore from "@/store/modules/common/modal/ModalStore";
Vue.use(Vuex);
export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        TenantStore,
        CurrentUserSessionStore,
        NewOrdersStore,
        CommonStore,
        ModalStore
    },
});
