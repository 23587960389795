import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
class DataAbout {
}
DataAbout.Breadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CMS } },
        text: i18n.t('breadcrumbs.cms'),
    },
    {
        disabled: true,
        text: i18n.t('breadcrumbs.about_us'),
    }
];
DataAbout.BreadcrumbsOurStory = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CMS } },
        text: i18n.t('breadcrumbs.cms'),
    },
    {
        disabled: true,
        text: i18n.t('breadcrumbs.our_story'),
    }
];
export default DataAbout;
