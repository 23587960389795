import axios from "axios";
const state = {
    addons: [],
    creditLimits: [],
    offers: [],
    languages: [],
};
const getters = {
    getPublicOffers(state) {
        return state.offers;
    },
    getPublicAddons(state) {
        return state.addons;
    },
    getPublicCreditLimits(state) {
        return state.creditLimits;
    },
    getPublicLanguages(state) {
        return state.languages;
    },
};
const mutations = {
    setPublicOffers(state, payload) {
        state.offers = payload;
    },
    setPublicAddons(state, payload) {
        state.addons = payload;
    },
    setPublicCreditLimits(state, payload) {
        state.creditLimits = payload;
    },
    setPublicLanguages(state, payload) {
        state.languages = payload;
    },
};
const actions = {
    async doUpdateCommonStore(ctx) {
        if (ctx.state.creditLimits && ctx.state.creditLimits.length == 0) {
            const offersPromise = axios.get("/api/web-api/public/shop/offer/get-public-offers").then(result => {
                ctx.commit("setPublicOffers", result.data.response);
            }).catch(err => {
                console.error(err);
            });
            const addonsPromise = axios.get("/api/web-api/public/shop/offer/get-public-addons").then(result => {
                ctx.commit("setPublicAddons", result.data.response);
            }).catch(err => {
                console.error(err);
            });
            const creditLimitsPromise = axios.get("/api/credit-limits").then(result => {
                ctx.commit("setPublicCreditLimits", result.data.response);
            }).catch(err => {
                console.error(err);
            });
            const languagesPromise = axios.get("/api/common/language").then(result => {
                ctx.commit("setPublicLanguages", result.data.response);
            }).catch(err => {
                console.error(err);
            });
            await Promise.all([offersPromise, addonsPromise, creditLimitsPromise, languagesPromise]);
        }
        return;
    }
};
export default {
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions
};
