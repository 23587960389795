export var RouteNames;
(function (RouteNames) {
    RouteNames["ROOT"] = "ROOT";
    RouteNames["UAT"] = "UAT";
    RouteNames["ADMIN"] = "ADMIN";
    RouteNames["ADMIN_USERS"] = "ADMIN_USERS";
    RouteNames["ADMIN_USERS_LIST"] = "ADMIN_USERS_LIST";
    RouteNames["ADMIN_USERS_EDIT"] = "ADMIN_USERS_EDIT";
    RouteNames["TOOLS_APPLICATION"] = "ADMIN_TOOLS";
    RouteNames["ADMIN_BLOG"] = "ADMIN_BLOG";
    RouteNames["ADMIN_BLOG_LIST"] = "ADMIN_BLOG_LIST";
    RouteNames["ADMIN_BLOG_CREATE"] = "ADMIN_BLOG_CREATE";
    RouteNames["ADMIN_BLOG_EDIT"] = "ADMIN_BLOG_EDIT";
    RouteNames["ADMIN_ADDONS"] = "ADMIN_ADDONS";
    RouteNames["ADMIN_PORTFOLIO_PROJECTS"] = "ADMIN_PORTFOLIO_PROJECTS";
    RouteNames["ADMIN_VILLAGE_PROJECTS"] = "ADMIN_VILLAGE_PROJECTS";
    RouteNames["ADMIN_PROMOS"] = "ADMIN_PROMOS";
    RouteNames["ADMIN_PROMO_HISTORY"] = "ADMIN_PROMO_HISTORY";
    RouteNames["CRM"] = "CRM";
    RouteNames["CMS"] = "CMS";
    RouteNames["CMS_FILES"] = "CMS_FILES";
    RouteNames["CMS_FILES_LIST"] = "CMS_FILES_LIST";
    RouteNames["CMS_FILES_CREATE"] = "CMS_FILES_CREATE";
    RouteNames["CMS_FILES_EDIT"] = "CMS_FILES_EDIT";
    RouteNames["CMS_FILES_CATEGORIES"] = "CMS_FILES_CATEGORIES";
    RouteNames["CMS_FEEDBACK"] = "CMS_FEEDBACK";
    RouteNames["CMS_FEEDBACK_CONTRACTS_LIST"] = "CMS_FEEDBACK_CONTRACTS_LIST";
    RouteNames["CMS_FEEDBACK_PARTNERS_LIST"] = "CMS_FEEDBACK_PARTNERS_LIST";
    RouteNames["CMS_MAIN_PAGE"] = "CMS_MAIN_PAGE";
    RouteNames["CMS_MAIN_PAGE_PROMO_LIST"] = "CMS_MAIN_PAGE_PROMO_LIST";
    RouteNames["CMS_MAIN_PAGE_WHY_UNDO_LIST"] = "CMS_MAIN_PAGE_WHY_UNDO_LIST";
    RouteNames["CMS_ABOUT_US"] = "CMS_ABOUT_US";
    RouteNames["CMS_ABOUT_US_LIST"] = "CMS_ABOUT_US_LIST";
    RouteNames["CMS_ABOUT_US_OUR_STORY_LIST"] = "CMS_ABOUT_US_OUR_STORY_LIST";
    RouteNames["CMS_VARIABLES"] = "CMS_VARIABLES";
    RouteNames["CMS_VARIABLES_LIST"] = "CMS_VARIABLES_LIST";
    RouteNames["CMS_SOURCE_FILES"] = "CMS_SOURCE_FILES";
    RouteNames["CMS_SELL_INFO"] = "CRM_SELL_INFO";
    RouteNames["CUSTOMER"] = "CUSTOMER";
    RouteNames["CUSTOMER_LIST"] = "CUSTOMER_LIST";
    RouteNames["CUSTOMER_VIEW"] = "CUSTOMER_VIEW";
    RouteNames["CUSTOMER_DETAILS_V2"] = "CUSTOMER_DETAILS_V2";
    RouteNames["DEVICES"] = "DEVICES";
    RouteNames["DEVICES_DEVICE"] = "DEVICES_DEVICE";
    RouteNames["DEVICES_WEARABLE"] = "DEVICES_WEARABLE";
    RouteNames["DEVICES_ESIM"] = "DEVICES_ESIM";
    RouteNames["ENTER"] = "ENTER";
    RouteNames["ENTER_LOGIN"] = "ENTER_LOGIN";
    RouteNames["ENTER_LOGIN_EMAIL"] = "ENTER_LOGIN_EMAIL";
    RouteNames["ENTER_LOGOUT"] = "ENTER_LOGOUT";
    RouteNames["ENTER_REGISTRATION"] = "ENTER_REGISTRATION";
    RouteNames["ENTER_FORGOT_PASSWORD"] = "ENTER_FORGOT_PASSWORD";
    RouteNames["HOME"] = "HOME";
    RouteNames["HOME_LIST"] = "HOME_LIST";
    RouteNames["HOME_DETAIL"] = "HOME_DETAIL";
    RouteNames["SLIDER"] = "SLIDER";
    RouteNames["SLIDER_SLIDES"] = "SLIDER_SLIDES";
    RouteNames["SLIDER_CREATE"] = "SLIDER_CREATE";
    RouteNames["SLIDER_EDIT"] = "SLIDER_EDIT";
    RouteNames["SLIDER_DISPLAY_SETTINGS"] = "SLIDER_DISPLAY_SETTINGS";
    RouteNames["SETTINGS"] = "SETTINGS";
    RouteNames["SETTINGS_EMAIL_NOTIFICATIONS"] = "SETTINGS_EMAIL_NOTIFICATIONS";
    RouteNames["SETTINGS_EMAIL_NOTIFICATIONS_LIST"] = "SETTINGS_EMAIL_NOTIFICATIONS_LIST";
    RouteNames["SETTINGS_EMAIL_NOTIFICATIONS_CREATE"] = "SETTINGS_EMAIL_NOTIFICATIONS_CREATE";
    RouteNames["SETTINGS_EMAIL_NOTIFICATIONS_EDIT"] = "SETTINGS_EMAIL_NOTIFICATIONS_EDIT";
    RouteNames["SETTINGS_GREEN_CONFIG"] = "SETTINGS_GREEN_CONFIG";
    RouteNames["SETTINGS_APPLICATION"] = "SETTINGS_APPLICATION";
    RouteNames["PROFILE"] = "PROFILE";
    RouteNames["FAQ"] = "FAQ";
    RouteNames["FAQ_QUESTIONS"] = "FAQ_QUESTIONS";
    RouteNames["FAQ_QUESTIONS_LIST"] = "FAQ_QUESTIONS_LIST";
    RouteNames["FAQ_QUESTIONS_CREATE"] = "FAQ_QUESTIONS_CREATE";
    RouteNames["FAQ_QUESTIONS_EDIT"] = "FAQ_QUESTIONS_EDIT";
    RouteNames["FAQ_QUESTIONS_CATEGORIES"] = "FAQ_QUESTIONS_CATEGORIES";
    RouteNames["ORDER"] = "ORDER";
    RouteNames["ORDER_LIST"] = "ORDER_LIST";
    RouteNames["ORDER_VIEW"] = "ORDER_VIEW";
})(RouteNames || (RouteNames = {}));
