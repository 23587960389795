import _ from "lodash";
class UtilDiff {
    static GetDiff(prev, curr) {
        const keys = _.union(_.keys(prev), _.keys(curr));
        return _.filter(keys, function (key) {
            return !_.eq(prev[key].toString(), curr[key].toString());
        });
    }
}
export default UtilDiff;
