import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import RouterView from "@/components/common/RouterView.vue";
import Layout from "@/components/Template/Layout.vue";
import cmsRoutes from "@/router/cms/index";
import enterRoutes from "@/router/enter/index";
import settingsRoutes from "@/router/settings/index";
import crmRoutes from "@/router/crm/index";
import { RouteNames } from "@/router/routes";
Vue.use(VueRouter);
const baseURL = process.env.BASE_URL || "/";
const routes = [
    {
        path: "/",
        name: RouteNames.ROOT,
        component: Layout,
        redirect: { name: RouteNames.HOME },
        children: [
            {
                path: "home",
                name: RouteNames.HOME,
                component: RouterView,
                redirect: { name: RouteNames.HOME_LIST },
                children: [
                    {
                        path: "",
                        name: RouteNames.HOME_LIST,
                        component: Home,
                    },
                    {
                        path: ":path",
                        name: RouteNames.HOME_DETAIL,
                        component: Home,
                    },
                ],
            },
            ...cmsRoutes,
            ...settingsRoutes,
            ...crmRoutes,
        ],
    },
    ...enterRoutes,
];
const router = new VueRouter({
    mode: "history",
    base: baseURL,
    routes,
});
export default router;
