import { RouteNames } from "@/router/routes";
import RouterView from "@/components/common/RouterView.vue";
import AddonsTariff from "@/views/CRM/AddonsTariff.vue";
import PortfolioProjects from "@/views/CRM/PortfolioProjects.vue";
import GreenConfig from "@/views/CRM/GreenConfig.vue";
import FeedbackContacts from "@/views/CRM/Feedback/FeedbackContacts.vue";
import FeedbackPartners from "@/views/CRM/Feedback/FeedbackPartners.vue";
import VillageProjects from "@/views/CRM/VillageProjects.vue";
import Promos from "@/views/CRM/Promo/Promos.vue";
import PromoHistory from "@/views/CRM/Promo/PromoHistory.vue";
import EUserEnum from "@/struct/user/EUserEnum";
var SystemRole = EUserEnum.SystemRole;
import CrmCustomerV2 from "@/views/CRM_V2/CustomerPage.vue";
import customerRoutes from "./customer";
import deviceRoutes from "./device";
import orderRoutes from "./order";
const crmRoutes = [
    {
        path: "crm2",
        name: RouteNames.CRM,
        component: RouterView,
        children: [
            {
                path: "addons",
                name: RouteNames.ADMIN_ADDONS,
                component: AddonsTariff,
                meta: {
                    access: [SystemRole.SuperAdmin, SystemRole.Admin],
                },
            },
            {
                path: "portfolio-projects",
                name: RouteNames.ADMIN_PORTFOLIO_PROJECTS,
                component: PortfolioProjects,
                meta: {
                    access: [SystemRole.SuperAdmin, SystemRole.Admin],
                },
            },
            {
                path: "village-projects",
                name: RouteNames.ADMIN_VILLAGE_PROJECTS,
                component: VillageProjects,
                meta: {
                    access: [SystemRole.SuperAdmin, SystemRole.Admin],
                },
            },
            {
                path: "promos",
                name: RouteNames.ADMIN_PROMOS,
                component: Promos,
                meta: {
                    access: [SystemRole.SuperAdmin, SystemRole.Admin],
                },
            },
            {
                path: "promo-history",
                name: RouteNames.ADMIN_PROMO_HISTORY,
                component: PromoHistory,
                meta: {
                    access: [SystemRole.SuperAdmin, SystemRole.Admin],
                },
            },
            {
                path: "green-config",
                name: RouteNames.SETTINGS_GREEN_CONFIG,
                component: GreenConfig,
                meta: {
                    access: [SystemRole.SuperAdmin, SystemRole.Admin],
                },
            },
            {
                path: "feedback",
                name: RouteNames.CMS_FEEDBACK,
                component: RouterView,
                redirect: { name: RouteNames.CMS_FEEDBACK_CONTRACTS_LIST },
                children: [
                    {
                        path: "contacts",
                        name: RouteNames.CMS_FEEDBACK_CONTRACTS_LIST,
                        component: FeedbackContacts,
                    },
                    {
                        path: "partners",
                        name: RouteNames.CMS_FEEDBACK_PARTNERS_LIST,
                        component: FeedbackPartners,
                    },
                ],
            },
            ...orderRoutes,
            ...customerRoutes,
            ...deviceRoutes,
        ],
    },
    {
        path: "/v2/crm/customer/:customer_uuid",
        name: RouteNames.CUSTOMER_DETAILS_V2,
        component: CrmCustomerV2,
    },
];
export default crmRoutes;
