import { __decorate } from "tslib";
import { Vue } from "vue-property-decorator";
import Component from 'vue-class-component';
import dayjs from "dayjs";
import { RouteNames } from "@/router/routes";
import EUserEnum from "@/struct/user/EUserEnum";
import { Getter } from "vuex-class";
import UtilGeneral from "@/util/Common/UtilGeneral";
let FormMixin = class FormMixin extends Vue {
    constructor() {
        super(...arguments);
        this.isItemsLoading = false;
        this.isSavingLoading = false;
        this.RouteNameList = RouteNames;
    }
    CanAdminAccess() {
        return (this.GetCurrentSessionUser != undefined && (this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.SuperAdmin));
    }
    formatDate(item, format = "DD.MM.YYYY HH:mm") {
        return item ? dayjs(item).format(format) : '';
    }
    CopyLink(link) {
        UtilGeneral.CopyLink(link);
    }
    parseItemsIntoEnum(obj = {}, isSimpleEnum = true) {
        return Object.entries(obj).filter(e => isSimpleEnum ? !isNaN(Number(e[0])) : true).map(([key, value]) => ({
            key: isSimpleEnum ? parseInt(key) : key, value
        }));
    }
    created() {
        this.currentUUID = this.$route.params.uuid;
    }
};
__decorate([
    Getter("getCurrentSessionUser")
], FormMixin.prototype, "GetCurrentSessionUser", void 0);
FormMixin = __decorate([
    Component
], FormMixin);
export default FormMixin;
