import { RouteNames } from "@/router/routes";
import i18n from "@/i18n";
import { Paths } from "@/router/paths";
class DataSellInfo {
}
DataSellInfo.DataSellInfoBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CRM } },
        text: i18n.t('breadcrumbs.crm'),
    },
    {
        disabled: true,
        text: "Sell info",
    }
];
export default DataSellInfo;
