import UtilEmail from "@/util/Common/UtilEmail";
import UtilSex from "@/util/Common/UtilSex";
class UtilFormValidation {
    static CreateOnMaxValueLengthRules(maxLength) {
        return [(value) => value.length <= maxLength || `Length not be ${maxLength}`];
    }
    static OnValidationIsRequired() {
        return [(v) => (v !== "" && !/^[\s]+$/.test(v) && !!v) || "Field required"];
    }
    static CreateOnMinValueLengthRules(minLength, allow_empty) {
        return [(value) => value.length >= minLength || (allow_empty && value.length == 0) || `Length must be not small then ${minLength}`];
    }
    static CreateOnPhoneValueRules() {
        return [(value) => /^[+][0-9]{11,12}$/g.test(value) || `Field not valid`];
    }
    static OnValidationExactNumber(length) {
        return [(value) => (value.length == length && /^\d+$/.test(value)) || `Length must be ${length}`];
    }
    static OnValidationSourceFileAnchorField() {
        return [(value) => /^[A-Za-z0-9_-]*$/.test(value) || `Field must be numbers and letters`];
    }
    static OnValidationDecimalField() {
        return [(value) => /^\d{0,6}(?:\.\d{1,2})?$/.test(value) || "Value format 00.00"];
    }
    static OnValidationOnlyNumbersField() {
        return [(value) => /^[0-9]*$/.test(value) || "Field must be only numbers"];
    }
    static OnValidationValidTo(validFrom) {
        return (value) => {
            const validFromDate = new Date(validFrom);
            const validToDate = new Date(value);
            return validToDate > validFromDate || "Valid to date must be after valid from date";
        };
    }
    static OnValidationSimpleType(type) {
        return [(value) => type == typeof value || `Value does not belong to a type ${type}`];
    }
    static OnValidationArrayType(type) {
        return [(value) => (value && value.split(",").every(x => typeof x === type)) || `Value does not belong to a type ${type}`];
    }
}
UtilFormValidation.OnValidationEmailRules = [(value) => value.length == 0 || UtilEmail.CanValidEmail(value) || "Not correct email"];
UtilFormValidation.OnValidationSexRules = [(value) => UtilSex.CanValidSex(value, true) || "Не корретный пол"];
UtilFormValidation.OnValidationPasswordRules = [(value) => value.length >= 6 || "Password length must not less 6", (value) => value.length <= 24 || "Password length must be 24"];
UtilFormValidation.OnValidationInn = [(value) => value.length == 10 || (value.length == 12 && /^\d+$/.test(value)) || "Введите корректный ИНН"];
UtilFormValidation.OnValidationOgrn = [(value) => value.length == 13 || (value.length == 15 && /^\d+$/.test(value)) || "Введите корректный ОГРН/ОГРНИП"];
export default UtilFormValidation;
