import axios from "axios";
export default class ApiMainPage {
    static async GetPromo(session_uuid) {
        try {
            const result = await axios.get("/api/admin/site-section/promo", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get devices");
        }
    }
    static async UpdatePromo(session_uuid, item) {
        try {
            const result = await axios.post("/api/admin/site-section/promo", item, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error update devices");
        }
    }
    static async GetWhyUndo(session_uuid) {
        try {
            const result = await axios.get("/api/admin/site-section/why_undo", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get why undo");
        }
    }
    static async UpdateWhyUndo(session_uuid, item) {
        try {
            const result = await axios.post("/api/admin/site-section/why_undo", item, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error update devices");
        }
    }
}
