import { RouteNames } from "@/router/routes";
import RouterView from "@/components/common/RouterView.vue";
import Customers from "@/views/CRM/Customer/Customers.vue";
import CustomerView from "@/views/CRM/Customer/CustomerView.vue";
const customerRoutes = [
    {
        path: "customer",
        name: RouteNames.CUSTOMER,
        component: RouterView,
        redirect: { name: RouteNames.CUSTOMER_LIST },
        children: [
            {
                path: "list",
                name: RouteNames.CUSTOMER_LIST,
                component: Customers
            },
            {
                path: "view/:uuid",
                name: RouteNames.CUSTOMER_VIEW,
                component: CustomerView
            }
        ]
    }
];
export default customerRoutes;
