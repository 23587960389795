import { EOrderProductStatus } from "@/views/CRM_V2/Customer/Interface/EOrderProductStatus";
export function getCustomerEmsCustomerId(customer) {
    return String(customer?.external_details?.customer_id ?? "-");
}
export function getProductSubId(customer, customer_products) {
    if (customer?.details?.msisdn?.uuid) {
        const customer_product = customer_products.find(cp => cp.customer_uuid == customer.uuid);
        if (customer_product) {
            return String(customer_product.subscription_id);
        }
    }
    return '-';
}
export function getProductMsisdn(customer, customer_products) {
    if (customer?.details?.msisdn?.uuid) {
        const customer_product = customer_products.find(cp => cp.customer_uuid == customer.uuid);
        if (customer_product) {
            return String(customer_product.msisdn);
        }
    }
    return '-';
}
export function getStatusNameOrderProduct(order_product) {
    if (order_product.status == 0 && order_product.system_details.product_ready == false) {
        return "InCart";
    }
    else if (order_product.status == 0 && order_product.system_details.product_ready == true) {
        return "ReadyToProcess";
    }
    else {
        const statuses = Object.values(EOrderProductStatus);
        return statuses.length < order_product.status ? String(statuses[order_product.status]) : '-';
    }
}
export function formatBelgianNumber(msisdn) {
    // Удаление всех символов кроме цифр
    let cleaned = msisdn.replace(/\D/g, '');
    if (!cleaned.startsWith('32') || cleaned.length != 11) {
        return msisdn;
    }
    if (!cleaned.startsWith('+32')) {
        cleaned = '+' + cleaned;
    }
    return cleaned.replace(/(\+32)(\d{2})(\d{3})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
}
