import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import CKEditor from "@ckeditor/ckeditor5-vue2";
Vue.config.productionTip = false;
Vue.use(CKEditor);
import i18n from "@/i18n/index";
import JsonViewer from "vue-json-viewer";
import axios from "axios";
Vue.use(JsonViewer);
axios.defaults.baseURL = process.env.VUE_APP_API_URL || "/";
new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
}).$mount("#app");
