import { RouteNames } from "@/router/routes";
import i18n from "@/i18n";
class DataCustomerDetails {
}
DataCustomerDetails.DataCustomerDetailsBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        disabled: true,
        text: "Customer Details",
    }
];
export default DataCustomerDetails;
