import axios from "axios";
export default class ApiAboutUs {
    static async GetAboutUs(session_uuid) {
        try {
            const result = await axios.get("/api/admin/site-section/about_us", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get about us");
        }
    }
    static async UpdateAboutUs(session_uuid, item) {
        try {
            const result = await axios.post("/api/admin/site-section/about_us", item, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error update devices");
        }
    }
    static async GetAboutUsOurStory(session_uuid) {
        try {
            const result = await axios.get("/api/admin/site-section/story", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get our story");
        }
    }
    static async UpdateAboutUsOurStory(session_uuid, item) {
        try {
            const result = await axios.post("/api/admin/site-section/story", item, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error update our story");
        }
    }
}
