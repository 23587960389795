export var EOrderProductStatus;
(function (EOrderProductStatus) {
    EOrderProductStatus[EOrderProductStatus["Ordered"] = 0] = "Ordered";
    EOrderProductStatus[EOrderProductStatus["SentToDelivery"] = 1] = "SentToDelivery";
    EOrderProductStatus[EOrderProductStatus["StartedActivation"] = 2] = "StartedActivation";
    EOrderProductStatus[EOrderProductStatus["StartedPortIn"] = 3] = "StartedPortIn";
    EOrderProductStatus[EOrderProductStatus["PortInFailed"] = 4] = "PortInFailed";
    EOrderProductStatus[EOrderProductStatus["SimActivated"] = 5] = "SimActivated";
    EOrderProductStatus[EOrderProductStatus["OrderCancelled"] = 6] = "OrderCancelled";
    EOrderProductStatus[EOrderProductStatus["ActivateFailed"] = 7] = "ActivateFailed";
})(EOrderProductStatus || (EOrderProductStatus = {}));
