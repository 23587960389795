import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
class DataVillages {
}
DataVillages.Breadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CRM } },
        text: i18n.t('breadcrumbs.crm')
    },
    {
        disabled: true,
        text: i18n.t('breadcrumbs.villages')
    }
];
DataVillages.TableHeaders = [
    {
        text: i18n.t('table_headers.project'),
        value: "title"
    },
    {
        text: "Use Tree?",
        value: "use_tree"
    },
    {
        text: i18n.t('table_headers.action'),
        width: '10%',
        value: "actions",
        sortable: false
    }
];
export default DataVillages;
