import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import en from "vuetify/src/locale/en";
import fr from "vuetify/src/locale/fr";
import { default as du } from "vuetify/src/locale/nl";
import "@mdi/font/css/materialdesignicons.css";
Vue.use(Vuetify);
export default new Vuetify({
    lang: {
        locales: { en, fr, du },
        current: "en",
    },
    icons: {
        iconfont: "mdi",
    },
});
