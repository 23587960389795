import Device from "@/views/CRM/Device/Device.vue";
import Wearable from "@/views/CRM/Device/Wearable.vue";
import ESim from "@/views/CRM/Device/ESim.vue";
import { RouteNames } from "@/router/routes";
import RouterView from "@/components/common/RouterView.vue";
import EUserEnum from "@/struct/user/EUserEnum";
var SystemRole = EUserEnum.SystemRole;
const deviceRoutes = [
    {
        path: "devices",
        name: RouteNames.DEVICES,
        component: RouterView,
        redirect: { name: RouteNames.DEVICES_DEVICE },
        meta: {
            access: [SystemRole.SuperAdmin, SystemRole.Admin],
        },
        children: [
            {
                path: "device",
                name: RouteNames.DEVICES_DEVICE,
                meta: {
                    access: [SystemRole.SuperAdmin, SystemRole.Admin],
                },
                component: Device
            },
            {
                path: "wearable",
                name: RouteNames.DEVICES_WEARABLE,
                meta: {
                    access: [SystemRole.SuperAdmin, SystemRole.Admin],
                },
                component: Wearable
            },
            {
                path: "esim",
                name: RouteNames.DEVICES_ESIM,
                meta: {
                    access: [SystemRole.SuperAdmin, SystemRole.Admin],
                },
                component: ESim
            }
        ]
    }
];
export default deviceRoutes;
