import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
import i18n from "@/i18n";
class DataUser {
}
DataUser.Breadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: "Home",
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.SETTINGS } },
        text: i18n.t('breadcrumbs.settings'),
    },
    {
        text: "Users managment",
        disabled: true
    },
];
DataUser.TableHeaders = [
    {
        text: "First name",
        value: "first_name"
    },
    {
        text: "Last name",
        value: "last_name"
    },
    {
        text: "Role",
        value: "system_role"
    },
    {
        text: "Status",
        value: "status"
    },
    {
        text: "Email",
        value: "email"
    },
    {
        text: "Action",
        value: "action",
        sortable: false,
        width: "10%"
    }
];
export default DataUser;
