import axios from "axios";
import { EOrderEnum } from "@/struct/order/EOrderEnum";
export default class ApiOrders {
    static async GetOrdersList(session_uuid) {
        try {
            const result = await axios.get("/api/admin/order/get-list", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get orders");
        }
    }
    static async GetOrdersListSearch(session_uuid, search, limit, page, statuses) {
        try {
            const result = await axios.get(`/api/admin/order/search?search=${search}&limit=${limit}&page=${page}&filter_statuses=${statuses}`, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get orders");
        }
    }
    static async GetOrderByUUID(session_uuid, uuid) {
        try {
            const result = await axios.get("/api/admin/order/get?order_uuid=" + uuid, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get order");
        }
    }
    static async UpdateNote(session_uuid, uuid, note) {
        try {
            const result = await axios.post("/api/admin/order/edit-note", {
                order_uuid: uuid,
                note: note,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async UpdateContactInfo(session_uuid, uuid, phone, email, type_customer) {
        const url = type_customer == EOrderEnum.CustomerType.Business ? "/api/admin/order/update-contact-info/company" : "/api/admin/order/update-contact-info/personal";
        try {
            const result = await axios.post(url, {
                customer_uuid: uuid,
                phone: phone,
                email: email,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async UpdateDeliveryAddress(session_uuid, uuid, item) {
        try {
            const result = await axios.post("/api/admin/order/update-delivery-address", {
                order_uuid: uuid,
                street_name: item.street_name,
                city: item.city,
                street_number: item.street_number,
                street_box: item.street_box,
                zip_code: item.zip_code,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async UpdatePortInInfo(session_uuid, order_product_uuid, port_old_sub_type, port_old_provider, port_phone_number) {
        try {
            const result = await axios.post("/api/admin/order/update-port-in", {
                order_product_uuid: order_product_uuid,
                port_old_sub_type: port_old_sub_type,
                port_old_provider: port_old_provider,
                port_phone_number: port_phone_number,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async UpdateDeliveryTracking(session_uuid, uuid, tracking, tracking_link) {
        try {
            const result = await axios.post("/api/admin/web-api/order/update-tracking", {
                uuid: uuid,
                tracking: tracking,
                tracking_link: tracking_link,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async GetOrderHistory(session_uuid, order_uuid) {
        try {
            const result = await axios.get("/api/admin/web-api/order/get-history?order_uuid=" + order_uuid, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async GetDonorsOperation() {
        try {
            const result = await axios.get("/api/admin/public/get-donor-operators");
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async ChangeSIMStatus(session_uuid, product_uuid, status) {
        try {
            const result = await axios.post("/api/admin/web-api/order/change-sim-status", {
                product_uuid: product_uuid,
                status: status,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async CancelOrderWithProducts(session_uuid, order_uuid) {
        try {
            const result = await axios.post("/api/admin/web-api/order/cancel", {
                order_uuid: order_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async ChangePaymentStatus(session_uuid, order_uuid, status) {
        try {
            const result = await axios.post("/api/admin/web-api/payment/status/to-refunded", {
                order_uuid: order_uuid,
                full_refunded: status === EOrderEnum.OrderPaymentStatus.Refunded,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async ChangeOrderStatusToProgress(session_uuid, order_uuid, status) {
        try {
            const result = await axios.post("/api/admin/order/update-pinned", {
                order_uuid: order_uuid,
                pinned_status: status
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async ChangeOrderStatusToDelivery(session_uuid, order_uuid) {
        try {
            const result = await axios.post("/api/admin/web-api/order/status/to-delivery", {
                order_uuid: order_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async GetNewOrdersLength(session_uuid) {
        try {
            const result = await axios.get("/api/admin/web-api/order/get-new-orders-length", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async ChangeMarkForOrderUUID(session_uuid, order_uuid, mark) {
        try {
            const result = await axios.post("/api/admin/order/edit-mark/", {
                order_uuid: order_uuid,
                mark: mark,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async ChangePinForOrderUUID(session_uuid, order_uuid, pin) {
        try {
            const result = await axios.post("/api/admin/order/update-pinned", {
                order_uuid: order_uuid,
                pinned_status: pin,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
}
