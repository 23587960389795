var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ListLayout',{attrs:{"breadcrumbs":_vm.Breadcrumbs,"page-header":_vm.$t('menu.green_config')},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.TableHeaders,"items":_vm.TableItems,"loading-text":_vm.$t('general.loading_table'),"loading":_vm.isTableLoading,"sort-by":"description","item-key":"key"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("menu.green_config")))]),_c('v-dialog',{attrs:{"scrollable":"","max-width":700},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("menu.green_config")))])]),_c('v-card-text',[_c('v-container',{staticClass:"px-0"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.editedItem.type === 'boolean')?_c('v-checkbox',{attrs:{"label":_vm.$t('settings.value', { type: 'boolean' }),"true-value":1,"false-value":0,"color":"indigo","hide-details":""},model:{value:(_vm.editedItem.value),callback:function ($$v) {_vm.$set(_vm.editedItem, "value", $$v)},expression:"editedItem.value"}}):(_vm.editedItem.type === 'integer'
                                                || _vm.editedItem.type === 'integer_single_list')?_c('v-text-field',{attrs:{"type":"number","hint":'1,23',"rules":_vm.validation.value.integer,"label":_vm.$t('settings.value', { type: _vm.editedItem.type === 'integer'
                                                              ? 'number' : 'single list of integer' })},model:{value:(_vm.editedItem.value),callback:function ($$v) {_vm.$set(_vm.editedItem, "value", _vm._n($$v))},expression:"editedItem.value"}}):(_vm.editedItem.type === 'string'
                                                || _vm.editedItem.type === 'string_single_list')?_c('v-text-field',{attrs:{"rules":_vm.validation.value.string,"hint":'some string',"label":_vm.$t('settings.value', { type: _vm.editedItem.type === 'string' ?
                                                              'string': 'single list of string' })},model:{value:(_vm.editedItem.value),callback:function ($$v) {_vm.$set(_vm.editedItem, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"editedItem.value"}}):(_vm.editedItem.type === 'string_list'
                                                            || _vm.editedItem.type === 'integer_list')?_c('v-textarea',{attrs:{"rows":"2","hint":'1,2,3',"rules":_vm.validation.value.string_list,"label":_vm.$t('settings.value', { type: _vm.editedItem.type === 'string_list'
                                                            ? 'list of string' : 'list of integer' })},on:{"input":function($event){_vm.editedItem.type === 'string_list' ? _vm.validateStr() : _vm.validateArrInt()}},model:{value:(_vm.editedItem.value),callback:function ($$v) {_vm.$set(_vm.editedItem, "value", $$v)},expression:"editedItem.value"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"rows":"2","hide-details":"","label":_vm.$t('settings.description')},model:{value:(_vm.editedItem.description),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"editedItem.description"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.isSavingLoading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("general.save"))+" ")])],1)],1)],1)],1)]},proxy:true},{key:`item.key`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.key.replaceAll('_', ' '))+" ")]}},{key:`item.value`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.type === 'boolean' ? !!item.value : item.value)+" ")]}},{key:`item.actions`,fn:function({ item }){return [(!item.url)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_c('router-link',{attrs:{"to":{ name: item.url }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.doLoadItems}},[_vm._v(" "+_vm._s(_vm.$t("general.retry_load"))+" ")])]},proxy:true}],null,true)})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }