export var EAdminPanelAddonProductType;
(function (EAdminPanelAddonProductType) {
    EAdminPanelAddonProductType["POSTPAID"] = "Post paid";
    EAdminPanelAddonProductType["PREPAID"] = "Pre paid";
    EAdminPanelAddonProductType["HYBRID"] = "Hybrid";
})(EAdminPanelAddonProductType || (EAdminPanelAddonProductType = {}));
export var EAdminPanelOfferInternalType;
(function (EAdminPanelOfferInternalType) {
    EAdminPanelOfferInternalType[EAdminPanelOfferInternalType["Unknown product"] = 0] = "Unknown product";
    EAdminPanelOfferInternalType[EAdminPanelOfferInternalType["Telecom product"] = 1] = "Telecom product";
    EAdminPanelOfferInternalType[EAdminPanelOfferInternalType["Project product"] = 2] = "Project product";
    EAdminPanelOfferInternalType[EAdminPanelOfferInternalType["Village product"] = 3] = "Village product";
})(EAdminPanelOfferInternalType || (EAdminPanelOfferInternalType = {}));
export var EAdminPanelOfferAddonType;
(function (EAdminPanelOfferAddonType) {
    EAdminPanelOfferAddonType[EAdminPanelOfferAddonType["addon"] = 0] = "addon";
    EAdminPanelOfferAddonType[EAdminPanelOfferAddonType["tariff"] = 1] = "tariff";
    EAdminPanelOfferAddonType[EAdminPanelOfferAddonType["village"] = 2] = "village";
    EAdminPanelOfferAddonType[EAdminPanelOfferAddonType["removal"] = 3] = "removal";
})(EAdminPanelOfferAddonType || (EAdminPanelOfferAddonType = {}));
