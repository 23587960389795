import i18n from "@/i18n";
import { RouteNames } from "@/router/routes";
import { Paths } from "@/router/paths";
class DataCustomerInfo {
}
DataCustomerInfo.Breadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CRM } },
        text: i18n.t('breadcrumbs.crm'),
    },
    {
        text: i18n.t('breadcrumbs.customer'),
        disabled: true
    }
];
DataCustomerInfo.ViewBreadcrumbs = [
    {
        to: { name: RouteNames.HOME },
        text: i18n.t('breadcrumbs.home'),
    },
    {
        to: { name: RouteNames.HOME_DETAIL, params: { path: Paths.CRM } },
        text: i18n.t('breadcrumbs.crm'),
    },
    {
        text: i18n.t('breadcrumbs.customer'),
        to: { name: RouteNames.CUSTOMER_LIST },
    },
    {
        text: i18n.t('breadcrumbs.customerView'),
        disabled: true
    }
];
DataCustomerInfo.TableHeaders = [
    {
        text: i18n.t("table_headers.created_at"),
        value: "create_at"
    },
    {
        text: i18n.t("table_headers.phone_number"),
        value: "details.msisdn.value",
    },
    {
        text: i18n.t("customer.email"),
        value: "details.email.value",
    },
    {
        text: i18n.t("table_headers.account_type"),
        value: "type",
    },
    {
        text: i18n.t("table_headers.user_type"),
        value: "owner_customer_uuid",
    },
    {
        text: i18n.t("table_headers.language"),
        value: "language",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "actions",
        sortable: false
    }
];
export default DataCustomerInfo;
