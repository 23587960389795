import axios from "axios";
export default class ApiEntity {
    static async GetVillages(session_uuid) {
        try {
            const result = await axios.get(`/api/admin/entity/get/list?entity=village`, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get entity");
        }
    }
    static async UpdateVillage(session_uuid, uuid, option_uuids) {
        try {
            const result = await axios.post("/api/admin/entity/update", {
                entity: "village",
                uuid: uuid,
                obj: {
                    "option_uuids": option_uuids
                },
                allowKeys: []
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                }
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error update villages");
        }
    }
}
