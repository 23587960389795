import axios from "axios";
export default class ApiSellInfo {
    static async GetSellInfo(startDate, endDate, type, session_uuid) {
        try {
            const response = await axios.get(`/api/admin/reports/sale?startDate=${startDate}&endDate=${endDate}&authType=${type}`, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return response.data.response;
        }
        catch (e) {
            console.error("Error in GetSellInfo", e);
            return false;
        }
    }
}
