import axios from "axios";
class ApiTranslation {
    static async GetTranslation(session_uuid, query) {
        try {
            const queryObj = {};
            if (query.key)
                queryObj['key'] = query.key;
            if (query.type)
                queryObj['type'] = query.type;
            if (query.locale)
                queryObj['locale'] = query.locale;
            let url = '/api/admin/translation';
            if (Object.keys(query).length > 0)
                Object.entries(query)
                    .forEach(([key, val], id) => { url = `${url}${id === 0 ? "?" : "&"}${key}=${val}`; });
            const result = await axios.get(url, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get translation");
        }
    }
    static async UpdateTranslation(session_uuid, items) {
        try {
            const result = await axios.post("/api/admin/translation", items, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
}
export default ApiTranslation;
